var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between mx-3 mb-1"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('div', [_c('strong', [_vm._t("default", function () {
    return [_vm._v(" " + _vm._s(_vm.value) + " ")];
  }, {
    "value": _vm.value
  })], 2), _vm._t("suffix"), _c('stat-btn', {
    staticClass: "ml-2",
    attrs: {
      "variable": _vm.param,
      "driver": _vm.driver
    }
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }