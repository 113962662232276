<template>
  <div class="app-pwm-slider">
    <vue-slider
      v-model="percentage"
      class="p-3"
      @change="updateValue"
      @drag-end="updateValue"
    />
    <div class="text-center app-pwm-slider__value" @click="getValue">
      <strong class="display-4 m-0 p-0">{{ percentage }}</strong> %
      <i class="fas fa-sync-alt fa-spin app-pwm-slider__icon" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { debounce } from 'throttle-debounce'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

import Api from '@/components/mixins/api'

export default {
  name: 'AppPwmSlider',

  props: {
    driver: {
      type: String,
      required: true
    },

    pwm: {
      type: Number,
      required: true
    },

    min: {
      type: Number,
      default: 1
    },

    max: {
      type: Number,
      default: 255
    }
  },

  components: {
    VueSlider
  },

  mixins: [Api],

  data() {
    return {
      percentage: 0,
      value: 0
    }
  },

  computed: {
    ...mapState(['config', 'driversData'])
  },

  methods: {
    getValue() {
      this.apiGet({
        type: 'function',
        driver: this.driver,
        func: 'pwm',
        params: `${this.pwm}`
      }).then(({ return_value: value }) => {
        this.percentage = value === 1 ? 0 : Math.floor((value * 100) / this.max)
      })
    },

    setValue: debounce(200, async function(newVal) {
      try {
        await this.apiGet({
          type: 'function',
          driver: this.driver,
          func: 'pwm',
          params: `${this.pwm}_${parseInt(newVal)}`
        })
      } catch (err) {
        console.log("Can't set value", err)
      }
    }),

    updateValue() {
      this.value = !this.percentage
        ? this.min
        : Math.floor((this.percentage * this.max) / 100)
      this.setValue(this.value)
    }
  },

  mounted() {
    this.getValue()
  }
}
</script>
<style lang="scss" scoped>
.app-pwm-slider__value {
  cursor: pointer;
  &:hover .app-pwm-slider__icon {
    opacity: 1;
  }
}
.app-pwm-slider__icon {
  opacity: 0;
  transition: all 0.5s ease;
}
</style>
