var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-0"
  }, [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "garaz",
      "pin": "24"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "garaz",
      "pin": "23"
    }
  })], 1)]), _vm._m(1), _c('div', {
    staticClass: "row"
  }, [_c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Info"
    }
  }, [_c('AppBox-param-row', {
    attrs: {
      "driver": "garaz",
      "param": "bmeTemperature",
      "label": "Temperatura:"
    }
  }), _c('AppBox-param-row', {
    attrs: {
      "driver": "garaz",
      "param": "bmePressure",
      "label": "Ciśnienie:"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s((parseFloat(value) / 100).toFixed(1)) + " ")];
      }
    }])
  }, [_c('span', {
    attrs: {
      "slot": "suffix"
    },
    slot: "suffix"
  }, [_vm._v("hPa")])]), _c('AppBox-param-row', {
    attrs: {
      "driver": "garaz",
      "param": "bmeHumidity",
      "label": "Wilgotność:"
    }
  }, [_c('span', {
    attrs: {
      "slot": "suffix"
    },
    slot: "suffix"
  }, [_vm._v("%")])])], 1), _c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Jasność"
    }
  }, [_c('app-pwm-slider', {
    attrs: {
      "driver": "garaz",
      "pwm": 3,
      "min": 1,
      "max": 200
    }
  })], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4"
  }, [_vm._v("Biuro")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4 mt-5"
  }, [_vm._v("inne")])]);

}]

export { render, staticRenderFns }