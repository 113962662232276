var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-pwm-slider"
  }, [_c('vue-slider', {
    staticClass: "p-3",
    on: {
      "change": _vm.updateValue,
      "drag-end": _vm.updateValue
    },
    model: {
      value: _vm.percentage,
      callback: function ($$v) {
        _vm.percentage = $$v;
      },
      expression: "percentage"
    }
  }), _c('div', {
    staticClass: "text-center app-pwm-slider__value",
    on: {
      "click": _vm.getValue
    }
  }, [_c('strong', {
    staticClass: "display-4 m-0 p-0"
  }, [_vm._v(_vm._s(_vm.percentage))]), _vm._v(" % "), _c('i', {
    staticClass: "fas fa-sync-alt fa-spin app-pwm-slider__icon"
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }