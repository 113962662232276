<template>
  <div class="d-flex justify-content-between mx-3 mb-1">
    <div>
      {{ label }}
    </div>
    <div>
      <strong>
        <slot :value="value">
          {{ value }}
        </slot>
      </strong>
      <slot name="suffix" />
      <stat-btn :variable="param" :driver="driver" class="ml-2" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import StatBtn from '@/components/helpers/stat-btn.vue'

export default {
  props: {
    driver: {
      type: String,
      required: true
    },

    param: {
      type: String,
      required: true
    },

    label: {
      type: String,
      default: ''
    }
  },

  components: {
    StatBtn
  },

  computed: {
    ...mapState(['driversData']),

    value() {
      if (this.driver in this.driversData) {
        const { [this.param]: value } = this.driversData[this.driver] || {}
        return value
      } else {
        return ''
      }
    }
  }
}
</script>
