<template>
  <div class="container px-0">
    <header>
      <h4 class="text-center my-4">Biuro</h4>
    </header>

    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="garaz" pin="24" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="garaz" pin="23" />
      </div>
    </div>

    <header>
      <h4 class="text-center my-4 mt-5">inne</h4>
    </header>

    <div class="row">
      <AppBox header="Info" class="col-xs-12 col-md-6 col-lg-4">
        <AppBox-param-row
          driver="garaz"
          param="bmeTemperature"
          label="Temperatura:"
        />

        <AppBox-param-row
          driver="garaz"
          param="bmePressure"
          label="Ciśnienie:"
        >
          <template v-slot="{ value }">
            {{ (parseFloat(value) / 100).toFixed(1) }}
          </template>
          <span slot="suffix">hPa</span>
        </AppBox-param-row>

        <AppBox-param-row
          driver="garaz"
          param="bmeHumidity"
          label="Wilgotność:"
        >
          <span slot="suffix">%</span>
        </AppBox-param-row>
      </AppBox>

      <AppBox header="Jasność" class="col-xs-12 col-md-6 col-lg-4">
        <app-pwm-slider driver="garaz" :pwm="3" :min="1" :max="200" />
      </AppBox>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import SwitchBox from '@/components/SwitchBox.vue'
import AppPwmSlider from '@/components/common/AppPwmSlider.vue'
import AppBoxParamRow from '@/components/common/AppBoxParamRow.vue'

export default {
  components: {
    SwitchBox,
    AppPwmSlider,
    AppBoxParamRow
  },

  computed: {
    ...mapState(['driversData']),

    garaz() {
      if ('garaz' in this.driversData) {
        return this.driversData.garaz
      } else {
        return false
      }
    }
  }
}
</script>
